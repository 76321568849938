<template>
  <div class="home">
    <div class="bgc">
      <div class="top-tit">
        <div class="ser org" v-if="!user.organizationId">
          <span>机构：</span>
          <el-select ref="org" @visible-change="$visibleChange($event, 'org')" v-model="cjgmc" placeholder="请选择" @change="serDev" filterable>
            <el-option v-for="(i, n) in cjgmcArr" :key="n" :value="i.id" :label="i.name"></el-option>
          </el-select>
        </div>
        <div class="ser dev">
          <span>设备：</span>
          <el-select ref="dev" @visible-change="$visibleChange($event, 'dev')" v-model="csbmc" placeholder="请选择" @change="getDatas" filterable>
            <el-option v-for="(i, n) in csbmcArr" :key="n" :value="i.id" :label="i.name"></el-option>
          </el-select>
        </div>
        <span>衰变池在线监测</span>
        <div class="right right1">取样器 {{ dose }} μSv/h</div>
        <!-- <div class="right">温度: {{ temp }}℃ 湿度: {{ humi }}%</div> -->
      </div>
      <div class="contentbox">
        <div v-for="item in boxArr" :key="item.id">
          <div class="charts"></div>
          <div class="title">{{ item.names + item.id }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deviceQueryWithPage, organizationQueryWithPage } from '@/util/http'
var echartsList = []
var myChart = []
export default {
  name: 'Home',
  data() {
    return {
      boxArr: [
        { id: '', res: '', names: '降解槽' },
        { id: '1', res: '', names: '衰变槽' },
        { id: '2', res: '', names: '衰变槽' },
        { id: '3', res: '', names: '衰变槽' },
        { id: '4', res: '', names: '衰变槽' },
        { id: '5', res: '', names: '衰变槽' },
        { id: '6', res: '', names: '衰变槽' },
      ],
      option: {
        series: [
          {
            type: 'liquidFill',
            shape: 'path://m72,294.39038c0,-107.80115 140.58529,-198.39034 307.88179,-198.39034l9.23645,0c167.2965,0 307.88179,90.5892 307.88179,198.39034l0,589.21944c0,107.80115 -140.58529,198.39034 -307.88179,198.39034l-9.23645,0c-167.2965,0 -307.88179,-90.5892 -307.88179,-198.39034l0,-589.21944z',
            radius: '95%', //显示比例
            center: ['50%', '50%'], //中心点
            amplitude: 20, //水波振幅
            data: [],
            color: ['#42b8fa'], //波浪颜色
            backgroundStyle: {
              borderWidth: 0,
              color: '#a2c4d3',
            },
            label: {
              position: ['50%', '30%'],
              formatter: '',
              fontSize: '34', //文本字号,
              color: '#fff',
            },
            outline: {
              show: false,
            },
          },
        ],
      },
      cjgmc: '',
      cjgmcArr: [],
      csbmc: '',
      csbmcArr: [],
      dose: 0, // 取样器
      temp: 0, // 温度
      humi: 0, // 湿度
      ws: null,
      keepAliveTimer: null, // 心跳定时器
      reconnectTimeOutInterval: null, // 重连定时器
      lastTime: new Date(), // 最后一次连接时间
      connectNum: 1, // ws重连次数
      is_open_socket: false, //websocket连接是否打开,避免重复连接
      is_first_online: false, //是否第一次在线
      is_first_offline: false, //是否第一次离线
      is_active_close_socket: false, //是否主动关闭ws
      loading: '',
      // troughNum: 7, // 槽数
      // troughHeight: 2, // 槽高度
      firstFlag: true,
      aotuLoading: '',
      user: this.$store.state.user,
      baseUrl: '',
    }
  },
  methods: {
    // 获取机构列表
    serDev() {
      this.aotuLoading = this.$loading({
        lock: true,
        text: '设备加载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
        target: document.querySelector('.home'),
      })
      this.csbmc = ''
      this.csbmcArr = []
      let params = {
        pageNo: 1,
        pageSize: 10000,
        useFlag: 0,
      }
      organizationQueryWithPage(params)
        .then((res) => {
          this.cjgmcArr = res.result.records
          if (this.cjgmc) {
            // if (this.firstFlag) {
            // this.cjgmc = this.$store.state.deviceId[0] || this.cjgmcArr[0].id
            // this.firstFlag = false
            this.getDevices(this.cjgmc)
          }
          // } else {
          this.aotuLoading.close()
          // }
        })
        .catch((err) => {
          this.aotuLoading.close()
        })
    },
    // 获取设备列表
    async getDevices(organizationId) {
      await this.clearData()
      let params = {
        organizationId,
        pageNo: 1,
        pageSize: 10000,
        useFlag: 0,
        type: 20,
      }
      deviceQueryWithPage(params)
        .then((data) => {
          this.csbmcArr = data.result.records
          if (this.csbmcArr.length) {
            // 一个设备自动连,多个设备但是从其他页跳转过来自动连
            // if (this.csbmcArr.length == 1 || this.$store.state.deviceId[1]) {
            //   this.csbmc = this.$store.state.deviceId[1] || this.csbmcArr[0].id
            //   let findRes = this.csbmcArr.find((item) => item.id === this.csbmc)
            //   this.troughNum = findRes.troughNum
            //   this.troughHeight = findRes.troughHeight
            //   this.$store.commit('DEVICEID', [])
            //   this.createSocket(this.csbmc)
            // }
            this.aotuLoading.close()
          } else {
            this.aotuLoading.close()
          }
        })
        .catch((err) => {
          this.aotuLoading.close()
        })
    },
    // 切换设备
    async getDatas() {
      await this.clearData()
      let findRes = this.csbmcArr.find((item) => item.id === this.csbmc)
      // this.troughNum = findRes.troughNum
      // this.troughHeight = findRes.troughHeight
      this.createSocket(this.csbmc)
    },
    // 建立连接
    createSocket(deviceId) {
      if ('WebSocket' in window) {
        this.ws = new WebSocket(`${this.baseUrl}?deviceId=${deviceId}`)
        this.ws.onopen = this.websocketonopen
        this.ws.onerror = this.websocketonerror
        this.ws.onmessage = this.websocketonmessage
        this.ws.onclose = this.websocketclose
      } else {
        // 浏览器不支持 WebSocket
        this.$message.error('您的浏览器不支持 WebSocket!')
      }
    },
    // 连接成功后调用
    websocketonopen() {
      this.loading = this.$loading({
        lock: true,
        text: '设备连接中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
        target: document.querySelector('.home'),
      })
      // this.$message.success('服务器连接成功!')
      if (this.reconnectTimeOutInterval) {
        clearTimeout(this.reconnectTimeOutInterval)
        this.reconnectTimeOutInterval = null
      }
      if (this.keepAliveTimer) {
        clearTimeout(this.keepAliveTimer)
        this.keepAliveTimer = null
      }
      this.connectNum = 1
      this.is_open_socket = true
      this.is_first_online = true
      this.is_first_offline = true
      this.is_active_close_socket = false
      this.lastTime = new Date()
      // 心跳机制
      this.keepAlive()
    },
    // 发生错误时调用
    websocketonerror() {
      console.log('连接已出错...')
      this.is_active_close_socket = false
    },
    // 接收消息
    websocketonmessage(e) {
      console.log(e)
      // 防止切换设备数据推送上一个id
      // if (this.csbmc != e.currentTarget.url.split('=')[1]) return
      if (this.is_first_online) {
        this.$message.success('设备连接成功!')
        this.is_first_online = false
        if (this.reconnectTimeOutInterval) {
          clearTimeout(this.reconnectTimeOutInterval)
          this.reconnectTimeOutInterval = null
        }
        this.connectNum = 1
      }
      this.lastTime = new Date()
      let evalData = eval('(' + e.data + ')').result
      this.dose = evalData.dose
      this.temp = evalData.temperature
      this.humi = evalData.humidity
      console.log('evalData', evalData)
      // 对象转数组,截取前 troughNum + 1 个
      let resArr = Object.values(evalData).slice(0, evalData.troughNum + 1)
      this.boxArr.forEach((v, i) => (v.res = resArr[i]))
      this.boxArr = this.boxArr.slice(0, evalData.troughNum + 1)
      for (var i = 0; i < this.boxArr.length; i++) {
        myChart = this.$echarts.getInstanceByDom(echartsList[i])
        if (myChart == null) {
          myChart = this.$echarts.init(echartsList[i])
        }
        this.option.series[0].data = [this.boxArr[i].res / evalData.troughHeight, this.boxArr[i].res / evalData.troughHeight, this.boxArr[i].res / evalData.troughHeight]
        this.option.series[0].label.formatter = this.boxArr[i].res + ' m'
        myChart.setOption(this.option)
      }
      this.loading.close()
    },
    // 关闭连接时调用
    websocketclose() {
      console.log(this.csbmc, '关闭连接')
      if (this.reconnectTimeOutInterval) {
        clearTimeout(this.reconnectTimeOutInterval)
        this.reconnectTimeOutInterval = null
      }
      if (this.keepAliveTimer) {
        clearTimeout(this.keepAliveTimer)
        this.keepAliveTimer = null
      }
      this.is_open_socket = false
      if (this.connectNum < 6) {
        this.loading.close()
        if (!this.is_active_close_socket) {
          this.$message.error(`服务器连接失败，正尝试第${this.connectNum}次连接`)
          this.reconnectTimeOutInterval = setTimeout(() => {
            this.createSocket(this.csbmc)
          }, 5000)
          this.connectNum += 1
        }
      } else {
        if (!this.is_active_close_socket) {
          this.$message.error('服务器连接失败，请刷新页面重试')
        }
        this.connectNum = 1
      }
      this.is_active_close_socket = false
    },
    // 心跳机制
    keepAlive() {
      console.log(this.csbmc, '心跳', new Date())
      if (new Date().getTime() - this.lastTime.getTime() > 1000 * 30) {
        if (this.is_first_offline) {
          this.$message.error('设备连接失败!')
          this.loading.close()
        }
        this.is_first_offline = false
        this.is_first_online = true
      } else {
        if (this.is_first_online && this.is_first_offline) {
          console.log('设备连接中...')
        }
      }
      if (this.keepAliveTimer) {
        clearTimeout(this.keepAliveTimer)
        this.keepAliveTimer = null
      }
      this.keepAliveTimer = setTimeout(() => {
        //判断当前webscokt状态
        if (this.ws.readyState) {
          //调用发送方法
          this.ws.send(
            JSON.stringify({
              type: 'Ping',
              t: new Date().getTime(),
            })
          )
          this.keepAlive()
        }
      }, 5000)
    },
    // 清空数据连接
    clearData() {
      if (this.ws && this.ws.readyState) {
        this.ws.close()
      }
      this.ws = null
      this.boxArr = [
        { id: '', res: '', names: '降解槽' },
        { id: '1', res: '', names: '衰变槽' },
        { id: '2', res: '', names: '衰变槽' },
        { id: '3', res: '', names: '衰变槽' },
        { id: '4', res: '', names: '衰变槽' },
        { id: '5', res: '', names: '衰变槽' },
        { id: '6', res: '', names: '衰变槽' },
      ]
      this.option.series[0].data = []
      this.option.series[0].label.formatter = ''
      this.dose = 0
      this.temp = 0
      this.humi = 0
      this.$nextTick(() => {
        this.init()
      })
      if (this.keepAliveTimer) {
        clearTimeout(this.keepAliveTimer)
        this.keepAliveTimer = null
      }
      if (this.reconnectTimeOutInterval) {
        clearTimeout(this.reconnectTimeOutInterval)
        this.reconnectTimeOutInterval = null
      }
      this.connectNum = 1
      this.is_open_socket = false
      this.is_active_close_socket = true
    },
    // 初始化
    init() {
      echartsList = document.querySelectorAll('.charts')
      for (var i = 0; i < echartsList.length; i++) {
        myChart = this.$echarts.getInstanceByDom(echartsList[i])
        if (myChart == null) {
          myChart = this.$echarts.init(echartsList[i])
        }
        myChart.setOption(this.option)
      }
    },
  },
  mounted() {
    var regExpDomain = /^[a-zA-Z0-9][a-zA-Z0-9\.\-]*\.[a-zA-Z]{2,4}$/
    // true 线上, false 本地
    if (regExpDomain.test(window.location.host)) {
      this.baseUrl = `wss://${location.host}/wss/ws`
    } else {
      this.baseUrl = `ws://${location.hostname}:20000/ws`
    }
    if (this.user.organizationId) {
      this.aotuLoading = this.$loading({
        lock: true,
        text: '设备加载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
        target: document.querySelector('.home'),
      })
      this.cjgmc = this.user.organizationId
      this.getDevices(this.cjgmc)
      document.getElementsByClassName('dev')[0].style.left = '15px'
    } else {
      this.serDev()
    }
    this.init()
  },
  beforeDestroy() {
    this.clearData()
  },
}
</script>
<style lang="less" scoped>
@font-face {
  font-family: ysh;
  src: url('../../public/icon/DS-DIGIT.TTF');
}
// 背景颜色
.home {
  background: #000469;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative; // 解决切换设备loading定位不准,作用到全局的情况
}
// 背景图片
.bgc {
  padding: 0 1% 0;
  height: 100%;
  background: url('../../public/home/wrapper-bg.png') no-repeat;
  background-size: 100% 100%;
}
// 中间标题
.top-tit {
  text-align: center;
  color: #fff;
  position: relative;
  margin-bottom: 15px;
  .ser {
    position: absolute;
    font-size: 18px;
    top: 1px;
    left: 15px;
    /deep/.el-input__inner {
      height: 32px;
      width: 180px;
      background: transparent;
      color: #fff;
    }
    /deep/.el-input__icon {
      line-height: 0;
    }
  }
  .org {
    left: 15px;
  }
  .dev {
    left: 260px;
  }
  .right {
    width: 200px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    position: absolute;
    right: 275px;
    top: 1px;
    border: 1px solid #fff;
    border-radius: 5px;
  }
  .right1 {
    right: 40px;
  }
}
.contentbox {
  height: calc(100% - 31px);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .title {
    color: #ffff;
    text-align: center;
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.charts {
  width: 400px;
  height: 300px;
}
.el-select-dropdown__item.selected {
  background: #409eff;
  color: #fff;
}
</style>
